
import { BinErrcCodes } from './index';
import { InvAttachedItem } from './index';
import { InvInventoryItemAdjustment } from './index';
import { InvInventoryItemReceived } from './index';
import { InvInventoryGroup } from './index';
import { LogReqLineItems } from './index';
import { LogUnitOfIssue } from './index';

export class InvInventoryItem {
   public inventoryItemId: number = 0;
   public barcode: string = null;
   public description: string = null;
   public assemblyIssued: boolean = null;
   public unitOfIssueId: string = null;
   public siteId: number = 0;
   public locationId: number = null;
   public subLocationId: number = null;
   public documentUrl: string = null;
   public tenantId: number = 0;
   public workOrderRequired: boolean = null;
   public missionCritical: boolean = null;
   public hazmat: boolean = null;
   public partNo: string = null;
   public minQty: number = null;
   public authorizedQty: number = null;
   public govtFurnished: boolean = null;
   public returnToGovt: boolean = null;
   public note: string = null;
   public inventoryGroupId: number = null;
   public ownerId: number = null;
   public manufacturerId: number = null;
   public errc: string = null;
   public difm: boolean = false;
   public expirationDate: Date = null;
   public nsn: string = null;
   public serialNo: string = null;
   public workSectionId: number = null;
   public autoReorder: boolean = false;
   public isActive: boolean = false;
   public inventoryVelocityInDays: number = null;
   public errcNavigation: BinErrcCodes = null;
   public invAttachedItem: InvAttachedItem[] = [];
   public invInventoryItemAdjustment: InvInventoryItemAdjustment[] = [];
   public invInventoryItemReceived: InvInventoryItemReceived[] = [];
   public inventoryGroup: InvInventoryGroup = null;
   public logReqLineItems: LogReqLineItems[] = [];
   public unitOfIssue: LogUnitOfIssue = null;
}