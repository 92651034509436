
import { AccTimeEntry } from './index';
import { AccTimeEntryAudit } from './index';
import { AllLocations } from './index';
import { ElecD008 } from './index';
import { EngCadDrawings } from './index';
import { FacDegreeDays } from './index';
import { FacEquipment } from './index';
import { FacFuelLevels } from './index';
import { FacFuelPeriodStatus } from './index';
import { FacFuelReceipts } from './index';
import { FacFuelReconciliation } from './index';
import { FacLandfillStatus } from './index';
import { FacMogasEstimates } from './index';
import { FacRefrigerantCylinders } from './index';
import { FacWasteDrums } from './index';
import { FacWeeklyStatusCompleted } from './index';
import { HrEmployees } from './index';
import { HrPsc } from './index';
import { ItAssetsHardware } from './index';
import { OpsMinPermPos } from './index';
import { OpsSan } from './index';
import { OpsScheduled } from './index';
import { OpsScheduledAudit } from './index';
import { QcInspectionReports } from './index';
import { QcObservations } from './index';
import { QcReportScheduleAsRequired } from './index';
import { SchMinPermPos } from './index';
import { SchScheduleItem } from './index';
import { SchTravelRequestLegs } from './index';
import { AllSectors } from './index';
import { NgTenants } from './index';
import { Ttt332 } from './index';
import { WoWorkOrders } from './index';

export class AllSites {
   public siteId: number = 0;
   public siteName: string = null;
   public siteCode: string = null;
   public sectorId: number = null;
   public siteIdMp2Del: string = null;
   public prsiteIdPost2015: string = null;
   public prsiteIdPost2014: string = null;
   public payrollSiteId: string = null;
   public logisticsCode: string = null;
   public officePhone: string = null;
   public fax: string = null;
   public companySite: boolean = false;
   public hasBeds: boolean = null;
   public active: boolean = false;
   public hughesNetIp: string = null;
   public shippingRoute: string = null;
   public dodacCode: string = null;
   public shipperDefault: string = null;
   public commercialPhone: string = null;
   public zip: string = null;
   public alphaCode: string = null;
   public maxDailyFlowGals: number = null;
   public payrollSiteName: string = null;
   public payrollSiteCode: string = null;
   public stationTechSlb: number = null;
   public serviceTechSlb: number = null;
   public stationMechanicSlb: number = null;
   public companyId: number = 0;
   public requiresStep2Approval: boolean = false;
   public oldTravelSiteId: number = null;
   public travelSiteCode: string = null;
   public travelMgmtsiteCode: string = null;
   public sanRequired: boolean = false;
   public hasFastPlant: boolean = false;
   public isDefault: boolean = false;
   public tenantId: number = 0;
   public accTimeEntry: AccTimeEntry[] = [];
   public accTimeEntryAudit: AccTimeEntryAudit[] = [];
   public allLocations: AllLocations[] = [];
   public elecD008: ElecD008[] = [];
   public engCadDrawings: EngCadDrawings[] = [];
   public facDegreeDays: FacDegreeDays[] = [];
   public facEquipment: FacEquipment[] = [];
   public facFuelLevels: FacFuelLevels[] = [];
   public facFuelPeriodStatus: FacFuelPeriodStatus[] = [];
   public facFuelReceipts: FacFuelReceipts[] = [];
   public facFuelReconciliation: FacFuelReconciliation[] = [];
   public facLandfillStatus: FacLandfillStatus[] = [];
   public facMogasEstimates: FacMogasEstimates[] = [];
   public facRefrigerantCylinders: FacRefrigerantCylinders[] = [];
   public facWasteDrums: FacWasteDrums[] = [];
   public facWeeklyStatusCompleted: FacWeeklyStatusCompleted[] = [];
   public hrEmployeesDefaultPaySite: HrEmployees[] = [];
   public hrEmployeesDefaultSite: HrEmployees[] = [];
   public hrEmployeesPermanentSite: HrEmployees[] = [];
   public hrPsc: HrPsc[] = [];
   public itAssetsHardware: ItAssetsHardware[] = [];
   public opsMinPermPos: OpsMinPermPos[] = [];
   public opsSan: OpsSan[] = [];
   public opsScheduled: OpsScheduled[] = [];
   public opsScheduledAudit: OpsScheduledAudit[] = [];
   public qcInspectionReports: QcInspectionReports[] = [];
   public qcObservations: QcObservations[] = [];
   public qcReportScheduleAsRequired: QcReportScheduleAsRequired[] = [];
   public schMinPermPos: SchMinPermPos[] = [];
   public schScheduleItem: SchScheduleItem[] = [];
   public schTravelRequestLegsTravelFrom: SchTravelRequestLegs[] = [];
   public schTravelRequestLegsTravelTo: SchTravelRequestLegs[] = [];
   public sector: AllSectors = null;
   public tenant: NgTenants = null;
   public ttt332: Ttt332[] = [];
   public woWorkOrders: WoWorkOrders[] = [];
}